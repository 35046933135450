@charset "UTF-8";

@font-face {
  font-family: 'Manrope';
  src: url('/assets/fonts/manrope-bold.woff2') format('woff2'),
    url('/assets/fonts/manrope-bold.woff') format('woff'),
    url('/assets/fonts/manrope-bold.otf') format('truetype');
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Manrope';
  src: url('/assets/fonts/manrope-semibold.woff2') format('woff2'),
    url('/assets/fonts/manrope-semibold.woff') format('woff'),
    url('/assets/fonts/manrope-semibold.otf') format('truetype');
  font-style: normal;
  font-weight: 500;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Ubuntu',
    'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: auto;
  background: #e9e9e9;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
  margin: 0;
}

a {
  transition: opacity 0.3s ease;
  text-decoration: none;
  word-wrap: break-word;
}

/**
 * ==============================================
 * CSS Loader
 * ==============================================
 */

.dot {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  -webkit-animation: dotFlashing 500ms infinite linear alternate;
          animation: dotFlashing 500ms infinite linear alternate;
  -webkit-animation-delay: 250ms;
          animation-delay: 250ms;
}

.dot::before,
.dot::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot::before {
  left: -15px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #fff;
  color: #fff;
  -webkit-animation: dotFlashing 500ms infinite alternate;
          animation: dotFlashing 500ms infinite alternate;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}

.dot::after {
  left: 15px;
  width: 7px;
  height: 7px;
  border-radius: 5px;
  background-color: #464646;
  color: #464646;
  -webkit-animation: dotFlashing 500ms infinite alternate;
          animation: dotFlashing 500ms infinite alternate;
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
}

@-webkit-keyframes dotFlashing {
  0% {
    background-color: #ffffff;
  }
  50%,
  100% {
    background-color: #464646;
  }
}

@keyframes dotFlashing {
  0% {
    background-color: #ffffff;
  }
  50%,
  100% {
    background-color: #464646;
  }
}

